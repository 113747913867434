import { motion, Variants } from "framer-motion"
import React, { ReactNode, ReactNodeArray } from "react"

const container: Variants = {
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
    },
  },
  hidden: {
    opacity: 0,
  },
}

interface IProps {
  children: ReactNode | ReactNodeArray
  className?: string
}

export default function Container({ children, className }: IProps) {
  return (
    <motion.div
      layout
      initial="hidden"
      animate="visible"
      variants={container}
      className={`px-8 md:px-24 text-gray-50 ${className}`}
    >
      {children}
    </motion.div>
  )
}
